import type { ButtonWrapperProps, ThumborQuality } from '@dce-front/dive';
import {
  Button,
  ExternalLinkIcon,
  MediaImage,
  THUMBOR_QUALITY_TO_DPR,
  getThumborUrl,
} from '@dce-front/dive';
import { THUMBOR } from '@dce-front/onewebapp-dive-utils';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from '../../lang';
import styles from './CTAFragment.module.css';

export type CTAFragmentProps = {
  button: {
    label: string;
    href: string;
    target?: string;
  };
  description?: string;
  URLImage?: string;
  altImage?: string;
  qualityUserSettings: ThumborQuality;
};

export function CTAFragment({
  button,
  description,
  URLImage,
  altImage,
  qualityUserSettings,
}: CTAFragmentProps): ReactNode {
  const { t } = useTranslation();
  const isThumborReady = THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some(
    (endpoint) => URLImage?.includes(endpoint),
  );
  const devicePixelRatio =
    qualityUserSettings && THUMBOR_QUALITY_TO_DPR[qualityUserSettings];

  const src = useMemo(() => {
    return (
      URLImage &&
      getThumborUrl({
        url: URLImage,
        dimensions: { width: 128, height: 97 },
        devicePixelRatio,
        includesAllowedSourceInUrl: isThumborReady,
        quality: qualityUserSettings,
      })
    );
  }, [URLImage, devicePixelRatio, isThumborReady, qualityUserSettings]);

  return (
    <div className={styles.CTAFragment}>
      <Button
        icon={
          button.target === '_blank' ? (
            <ExternalLinkIcon title={t('CreativeMedia.Icon.externalLink')} />
          ) : undefined
        }
        renderWrapper={({ className, children }: ButtonWrapperProps) => (
          <a
            target={button.target}
            href={button.href}
            className={className}
            rel="noreferrer"
          >
            {children}
          </a>
        )}
      >
        {button.label}
      </Button>
      <div className={styles.CTAFragment__partner}>
        {description && <p>{description}</p>}
        {src && (
          <MediaImage
            alt={altImage}
            src={src.url}
            width={128}
            height="auto"
            className={styles.CTAFragment__parterLogo}
          />
        )}
      </div>
    </div>
  );
}
