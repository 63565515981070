import { Button, type ButtonWrapperProps, DownloadIcon } from '@dce-front/dive';
import type { ReactNode } from 'react';
import styles from './DownloadFragment.module.css';

/**
 * Download fragment
 * @param {string} title     title of the downloadable fragment
 * @param {array}  documents e.g. {links: [], filename: "t.pdf", contentType:""}
 */

export type DownloadFragmentProps = {
  title: string;
  documents: {
    links?: string[];
    filename: string;
    contentType: string;
  }[];
};

export function DownloadFragment({
  title,
  documents,
}: DownloadFragmentProps): ReactNode {
  const { contentType, filename, links } = documents[0] || {};

  if (!links) {
    return null;
  }

  return (
    <div className={styles.downloadFragment}>
      <Button
        icon={<DownloadIcon />}
        variant="secondary"
        renderWrapper={({
          id,
          disabled,
          className,
          children,
        }: ButtonWrapperProps) => (
          <a
            target="_blank"
            title={filename}
            rel="noreferrer"
            href={links[0]}
            type={contentType}
            id={id}
            className={className}
            aria-disabled={disabled}
          >
            {children}
          </a>
        )}
        key={filename}
      >
        {title}
      </Button>
    </div>
  );
}
