import { isSomeEnum } from '@dce-front/onewebapp-utils';

export enum VideoType {
  Pfv = 'pfv',
  Hapi = 'hapi',
  Dailymotion = 'dailymotion',
  Youtube = 'youtube',
}

export const isVideoType = isSomeEnum(VideoType);
