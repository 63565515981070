import type { JSX } from 'react';
import { dateFormat } from '../../helpers/date';
import { useTranslation } from '../../lang';
import styles from './AuthorFragment.module.css';

export type AuthorFragmentProps = {
  author?: string;
  date?: string;
  langKey: string;
};

export function AuthorFragment({
  author,
  date,
  langKey,
}: AuthorFragmentProps): JSX.Element {
  const { t } = useTranslation();

  const displayDate =
    date !== undefined &&
    langKey !== undefined &&
    t('CreativeMedia.MediaAuthor.date', { date: dateFormat(date, langKey) });

  return (
    <div className={styles.authorFragment}>
      <span className={styles.authorFragment__author}>{author}</span>
      <span>{displayDate}</span>
    </div>
  );
}
