import type { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import type { OfferLocation, OfferZone } from '@canalplus/sdk-core';
import { FragmentContentType } from '@canalplus/sdk-hodor';
import type { ThumborQuality } from '@dce-front/dive';
import type { ApiV2ArticleFragmentContent } from '@dce-front/hodor-types/api/v2/article/definitions';
import classNames from 'classnames/bind';
import type { JSX, ReactNode } from 'react';
import { PictureFragment } from '../Fragments/PictureFragment/PictureFragment';
import { VideoFragment } from '../Fragments/VideoFragment/VideoFragment';
import { isVideoType } from '../constants/videoTypes';
import styles from './Cover.module.css';

const cx = classNames.bind(styles);

export type CoverProps = {
  type: FragmentContentType;
  content: ApiV2ArticleFragmentContent;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  qualityUserSettings: ThumborQuality;
  isImmersive: boolean;
  playerCredentials?: IAPICredentialsRaw;
};

export function Cover({
  type,
  content,
  offerLocation,
  offerZone,
  qualityUserSettings,
  isImmersive,
  playerCredentials,
}: CoverProps): JSX.Element {
  const components: { [Type in FragmentContentType]?: ReactNode } = {
    [FragmentContentType.MainImage]: content?.media?.links?.[0]?.href && (
      <div className={styles.cover__picture}>
        <PictureFragment
          url={content?.media?.links?.[0].href}
          type="cover"
          description={content?.media?.description}
          qualityUserSettings={qualityUserSettings}
        />
      </div>
    ),

    [FragmentContentType.Video]:
      isVideoType(content?.source) && content?.id ? (
        <div
          className={cx('cover__video', {
            'cover__video--immersive': isImmersive,
          })}
        >
          <VideoFragment
            id={content.id.toString()}
            offerLocation={offerLocation}
            offerZone={offerZone}
            type={content.source}
            playerCredentials={playerCredentials}
          />
        </div>
      ) : null,
  };

  return <section className={styles.cover}>{components[type]}</section>;
}
