import { isSomeEnum } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import styles from './Heading.module.css';
import type { HeadingHTMLTags, HeadingProps } from './HeadingTypes';
import { HeadingLevels } from './HeadingTypes';

const DEFAULT_LEVEL = HeadingLevels.LEVEL_2;

export function HeadingContent({
  level = DEFAULT_LEVEL,
  text,
  isBig,
  isFromDetail = false,
  isFromShowcase = false,
}: HeadingProps): JSX.Element {
  let headingLevel = isSomeEnum(HeadingLevels)(level) ? level : DEFAULT_LEVEL;

  if (isBig) {
    headingLevel = HeadingLevels.LEVEL_1;
  }

  const TitleTag = `h${headingLevel}` as HeadingHTMLTags;
  return (
    <TitleTag
      data-testid="title-tag"
      className={classNames(styles.heading, {
        [styles['heading--detailV5']]: isFromDetail,
        [styles['heading--showcase']]: isFromShowcase,
        [styles['heading--is-big']]: isBig,
        [styles['heading--is-small']]: headingLevel >= HeadingLevels.LEVEL_3,
      })}
    >
      <span className={styles.text}>{text}</span>
    </TitleTag>
  );
}
