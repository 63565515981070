/**
 * Inject a string script tag to a DIV element for execution
 *
 * @param scriptTag - The script tag as a string
 * @param target - The parent element contains the injected script
 * @example
 * injectScriptToElement('<script>console.log(1);</script>', div);
 * // returns <div><script>console.log(1);</script></div>
 * injectScriptToElement('<script async="true" src="bundle-api.js"></script>', div);
 * // returns <div><script async="true" src="bundle-api.js"></script></div>
 */
export const injectScriptToElement = (
  scriptTag: string,
  target: HTMLDivElement,
): void => {
  if (!scriptTag) {
    return;
  }
  const divElement = document.createElement('div');
  divElement.innerHTML = scriptTag;

  const scriptElement = divElement.getElementsByTagName('script')[0];

  if (!scriptElement) {
    return;
  }

  const scriptNode = document.createElement('script');

  /** Copy original attributes to the newly created script tag */
  Object.values(scriptElement.attributes).forEach((attr) => {
    scriptNode.setAttribute(attr.name, attr.value);
  });

  /** Copy script body if any */
  if (scriptElement.text) {
    scriptNode.text = scriptElement.text;
  }

  target.appendChild(scriptNode);
};
