import type { JSX } from 'react';
import { HeadingContent } from './HeadingContent';
import { HeadingPlaceholder } from './HeadingPlaceholder';
import type { HeadingProps } from './HeadingTypes';

/**
 * Heading
 *
 * renders a page title or its placeholder
 */
export function Heading(props: HeadingProps): JSX.Element {
  const HeadingComponent = props?.text ? HeadingContent : HeadingPlaceholder;
  return <HeadingComponent {...props} />;
}
