import type { ThumborQuality } from '@dce-front/dive';
import { THUMBOR } from '@dce-front/onewebapp-dive-utils';
import type { I18nTranslationFunction } from '@dce-front/onewebapp-i18n';
import { useId, type JSX } from 'react';
import { PictureFragment } from '../Fragments/PictureFragment/PictureFragment';
import { LangProvider } from '../lang';
import styles from './CreativeMediaCard.module.css';

export type CreativeMediaCardProps = {
  image: string;
  title?: string;
  qualityUserSettings?: ThumborQuality;
  t: I18nTranslationFunction<any>;
  isImmersive: boolean;
  description?: string;
};

export function CreativeMediaCard({
  image,
  title = '',
  qualityUserSettings = THUMBOR.QUALITY_DEFAULT,
  isImmersive,
  t,
  description,
}: CreativeMediaCardProps): JSX.Element {
  const id = useId();

  return (
    <LangProvider value={{ t }}>
      <div
        className={styles.card}
        aria-labelledby={`article-title-${id} article-img-${id} article-label-${id}`}
      >
        <PictureFragment
          id={`article-img-${id}`}
          url={image}
          qualityUserSettings={qualityUserSettings}
          type="card"
          isImmersive={isImmersive}
          description={description}
        />
        <div className={styles.card__content}>
          <span className={styles.card__article} id={`article-label-${id}`}>
            {t('CreativeMedia.Card.article')}
          </span>
          <p className={styles.card__title} id={`article-title-${id}`}>
            {title}
          </p>
        </div>
      </div>
    </LangProvider>
  );
}
