/** Return correct date from timestamp */
export const dateFormat = (
  date: string | number | Date,
  langKey: string,
): string => {
  return new Date(date).toLocaleDateString(langKey.slice(0, 2), {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};
